import React, {useState}  from 'react';
import CommonPopup from '../PopupForm/CommonPopup';
import * as Yup from 'yup';
import { Link } from '@mui/material';

const GrowBusiness = () => {
  return (
    <>
      <div className='w-full'  style={{ backgroundImage: `url(${process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Grow-Your-Business-With-Us.gif'})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
        <div className=' w-11/12 xl:max-w-screen max-w-screen-2xl mx-auto h-auto text-center pt-20 pb-20 text-white'>
          <p className='m-0'>Unique Expert Analysis</p>
          <h2 className='text-3xl'>One Platform For Innovative Solutions</h2>
          <button className='btn mt-4'>
            <Link href="/request-for-proposal" style={{color: 'white'}}>Learn More</Link></button>
          </div>
      </div>
    </>
  )
}
export default GrowBusiness;
